import React from 'react'
import Lottie from "lottie-react";
import AnimationData from "../assets/animation_lkig4h0u.json"

const Maintenance = () => {
  return (
    <>
        <Lottie animationData={AnimationData} style={{width: "100vw", height: "100vh"}}  />
    </>
  )
}

export default Maintenance